import { docReady, windowReady } from "./utils/utils";
import { viz } from "./in-issue";
import { inIssueArticles } from "./in-issue-articles";

docReady(() => {});

function initFlickity(carousel) {
    // @ts-expect-error
    let flkty = new Flickity(carousel, {
        cellAlign: "center",
        contain: true,
        resize: true,
        pageDots: true,
        imagesLoaded: true,
        groupCells: true,
        freeScroll: true,
        draggable: ">0",
    });

    return flkty;
}

windowReady(() => {
    let carousels = document.querySelectorAll(".carousel-inner");

    for (let carousel of carousels) {
        let flkty = initFlickity(carousel);

        let parentElem = carousel.parentElement;
        let orderByElem: HTMLInputElement = parentElem.querySelector(".orderByPublishDate");
        let searchTitleElem: HTMLInputElement = parentElem.querySelector(".searchTitle");

        if (orderByElem) {
            orderByElem.addEventListener("change", function () {
                let items = [];
                let elems: NodeListOf<HTMLElement> = carousel.querySelectorAll(".carousel-item");

                for (let elem of elems) {
                    let date = null;

                    if (
                        orderByElem.value === "originalPublishDate" &&
                        elem.getAttribute("data-dateric")
                    ) {
                        date = elem.getAttribute("data-datepublish");
                        elem.style.display = "inline-block";
                    }

                    if (orderByElem.value === "ricPublishDate") {
                        date = elem.getAttribute("data-dateric");
                        elem.style.display = "inline-block";
                    }

                    if (orderByElem.value === "sortTitle") {
                        date = elem.getAttribute("data-title");
                        elem.style.display = "inline-block";
                    }

                    if (orderByElem.value === "soon") {
                        date = elem.getAttribute("data-soon");
                        if (date === "false") {
                            elem.style.display = "none";
                        } else {
                            elem.style.display = "inline-block";
                        }
                    }

                    items.push({
                        elem: elem,
                        date: date,
                    });
                }

                if (orderByElem.value === "ricPublishDate") {
                    items.sort((a, b) => {
                        if (a.date > b.date) {
                            return -1;
                        }
                        if (a.date < b.date) {
                            return 1;
                        }
                    });
                } else {
                    items.sort((a, b) => {
                        if (a.date < b.date) {
                            return -1;
                        }
                        if (a.date > b.date) {
                            return 1;
                        }
                    });
                }

                flkty.destroy();

                let soonArray = [];
                for (let item of items) {
                    if (
                        item.elem.getAttribute("data-soon") &&
                        item.elem.getAttribute("data-soon") === "true"
                    ) {
                        soonArray.push(item);
                        item.elem.style.display = "none";
                    } else {
                        carousel.append(item.elem);
                    }
                }

                for (let item of soonArray) {
                    if (orderByElem.value === "soon") {
                        item.elem.style.display = "inline-block";
                    }
                    carousel.append(item.elem);
                }

                flkty = initFlickity(carousel);
            });
        }

        if (searchTitleElem) {
            searchTitleElem.addEventListener("input", function () {
                let elems: NodeListOf<HTMLElement> = carousel.querySelectorAll(".carousel-item");

                if (searchTitleElem.value.length >= 1) {
                    for (let elem of elems) {
                        let title = elem.getAttribute("data-title");

                        if (!title.toLowerCase().includes(searchTitleElem.value.toLowerCase())) {
                            elem.style.display = "none";
                        } else {
                            elem.style.display = "inline-block";
                        }
                    }
                } else {
                    for (let elem of elems) {
                        elem.style.display = "inline-block";
                    }
                }

                flkty.destroy();
                flkty = initFlickity(carousel);
            });
        }
    }

    // Mobile menu
    let menuCalloutElem = document.querySelector(
        "#menu-bar > div .menu-responsive-call .menu-responsive-caller",
    );
    if (menuCalloutElem) {
        menuCalloutElem.addEventListener("click", () => {
            let bodyElem = document.querySelector("body");
            bodyElem.classList.toggle("responsive-menu");
        });
    }

    let menuCloseElem = document.querySelector("#menu-bar > div .menu-responsive .close-menu");
    if (menuCloseElem) {
        menuCloseElem.addEventListener("click", () => {
            let bodyElem = document.querySelector("body");
            bodyElem.classList.toggle("responsive-menu");
        });
    }

    // Mobile secondary menus
    let secondaryMenuSelects: NodeListOf<HTMLSelectElement> = document.querySelectorAll(
        ".magazine-secondary-nav-wrapper .secondary-nav-select",
    );
    for (let secondaryMenuSelect of secondaryMenuSelects) {
        secondaryMenuSelect.addEventListener("change", () => {
            let selectedOption: HTMLOptionElement =
                secondaryMenuSelect.options[secondaryMenuSelect.selectedIndex];
            let url = selectedOption.dataset["href"];
            window.location.href = url;
        });
    }

    // In-issue
    let inIssuePageElem = document.querySelector("#in-issue .in-issue-page");
    if (inIssuePageElem) {
        viz();
    }

    inIssueArticles();
});
