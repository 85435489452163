export const inIssueArticles = () => {
    let screenCover = document.querySelector(".article-pop-container");
    let closeButtons = document.querySelectorAll(".article-pop-container .close-button .close");

    let articleCallers: NodeListOf<HTMLElement> = document.querySelectorAll("a[data-article-info]");

    for (let articleCallerElem of articleCallers) {
        let articleId = articleCallerElem.dataset["articleInfo"];

        articleCallerElem.addEventListener("click", (e) => {
            e.preventDefault();

            let articlePopElem = document.querySelector(
                ".article-pop-container .article[data-article-id='" + articleId + "']",
            );
            screenCover.classList.add("pop");
            articlePopElem.classList.add("pop");
        });
    }

    if (screenCover) {
        screenCover.addEventListener("click", (e) => {
            if (screenCover !== e.target) {
                return;
            }

            let currentPops = document.querySelectorAll(".article-pop-container .article.pop");
            for (let pop of currentPops) {
                pop.classList.remove("pop");
            }
            screenCover.classList.remove("pop");
        });
    }

    if (closeButtons) {
        for (let closeButton of closeButtons) {
            closeButton.addEventListener("click", () => {
                let currentPops = document.querySelectorAll(".article-pop-container .article.pop");
                for (let pop of currentPops) {
                    pop.classList.remove("pop");
                }
                screenCover.classList.remove("pop");
            });
        }
    }
};
