export const viz = () => {
    let viewport: HTMLElement = document.querySelector("#in-issue .in-issue-page");
    let imageSrc: HTMLImageElement = document.querySelector("#in-issue .in-issue-page img");
    let zoomInfoElem = document.querySelector(".controls .zoom");

    if (!imageSrc || !viewport) {
        return;
    }

    let image = {
        width: imageSrc.width,
        height: imageSrc.height,
    };

    let state = {
        rotation: 0,
        zoom: 100,
        top: 0,
        left: 0,
        width: image.width,
        height: image.height,
        viewWidth: viewport.offsetWidth,
        viewHeight: viewport.offsetHeight,
    };

    let canvas = document.createElement("canvas");
    let canvasContext = canvas.getContext("2d");
    viewport.appendChild(canvas);

    canvas.setAttribute("width", String(image.width));
    canvas.setAttribute("height", String(image.height));
    canvasContext.rotate((state.rotation * Math.PI) / 180);
    canvasContext.drawImage(imageSrc, 0, 0, image.width, image.height);

    let zoomInElem = document.querySelector(".in-issue-page .controls .zoom-in");
    zoomInElem.addEventListener("click", () => {
        zoomIn();
    });

    let zoomOutElem = document.querySelector(".in-issue-page .controls .zoom-out");
    zoomOutElem.addEventListener("click", () => {
        zoomOut();
    });

    let fitWElem = document.querySelector(".in-issue-page .controls .fit-width");
    fitWElem.addEventListener("click", () => {
        fitW();
    });

    let fitElem = document.querySelector(".in-issue-page .controls .fit");
    fitElem.addEventListener("click", () => {
        fit();
    });

    let rotateLeftElem = document.querySelector(".in-issue-page .controls .rotate-left");
    rotateLeftElem.addEventListener("click", () => {
        rotateLeft();
    });

    let rotateRightElem = document.querySelector(".in-issue-page .controls .rotate-right");
    rotateRightElem.addEventListener("click", () => {
        rotateRight();
    });

    let oneOnOneElem = document.querySelector(".in-issue-page .controls .one-on-one");
    oneOnOneElem.addEventListener("click", () => {
        oneOnOne();
    });

    window.addEventListener("resize", windowResize);

    dragToScroll();

    fit();

    function windowResize() {
        (state.viewWidth = viewport.offsetWidth), (state.viewHeight = viewport.offsetHeight), fit();
    }

    function oneOnOne() {
        state.zoom = 1;
        updateCanvas();
    }

    function fit() {
        let percentageWidth = (state.viewWidth - 70) / image.width;
        let percentageHeight = (state.viewHeight - 230) / image.height;

        if (state.rotation === 90 || state.rotation === 270) {
            percentageWidth = (state.viewWidth - 70) / image.height;
            percentageHeight = (state.viewHeight - 230) / image.width;
        }

        if (percentageWidth <= percentageHeight) {
            state.zoom = percentageWidth;
        } else {
            state.zoom = percentageHeight;
        }

        updateCanvas();
    }

    function fitW() {
        state.zoom = (state.viewWidth - 70) / image.width;
        updateCanvas();
    }

    function zoomIn() {
        state.zoom += 0.2;
        updateCanvas();
    }

    function zoomOut() {
        state.zoom -= 0.2;
        updateCanvas();
    }

    function rotateLeft() {
        let nextRotation = state.rotation - 90;
        if (nextRotation < 0) {
            nextRotation = 270;
        }

        updateCanvas(nextRotation);
    }

    function rotateRight() {
        let nextRotation = state.rotation + 90;
        if (nextRotation > 270) {
            nextRotation = 0;
        }
        updateCanvas(nextRotation);
    }

    function updateCanvas(nextRotation = null) {
        if (nextRotation !== null && nextRotation != state.rotation) {
            state.rotation = nextRotation;
            canvas.style.opacity = "0";

            switch (state.rotation) {
                case 0:
                    canvas.setAttribute("width", String(image.width));
                    canvas.setAttribute("height", String(image.height));
                    canvasContext.rotate((state.rotation * Math.PI) / 180);
                    canvasContext.drawImage(imageSrc, 0, 0, image.width, image.height);
                    break;

                case 90:
                    canvas.setAttribute("width", String(image.height));
                    canvas.setAttribute("height", String(image.width));
                    canvasContext.rotate((state.rotation * Math.PI) / 180);
                    canvasContext.drawImage(imageSrc, 0, -image.height, image.width, image.height);
                    break;

                case 180:
                    canvas.setAttribute("width", String(image.width));
                    canvas.setAttribute("height", String(image.height));
                    canvasContext.rotate((state.rotation * Math.PI) / 180);
                    canvasContext.drawImage(
                        imageSrc,
                        -image.width,
                        -image.height,
                        image.width,
                        image.height,
                    );
                    break;

                case 270:
                    canvas.setAttribute("width", String(image.height));
                    canvas.setAttribute("height", String(image.width));
                    canvasContext.rotate((state.rotation * Math.PI) / 180);
                    canvasContext.drawImage(imageSrc, -image.width, 0, image.width, image.height);
                    break;
            }
            state.width = canvas.width;
            state.height = canvas.height;

            fit();
            return;
        }

        if (state.zoom < 0.1) {
            state.zoom = 0.1;
        }
        if (state.zoom > 1.5) {
            state.zoom = 1.5;
        }

        if (state.rotation === 90 || state.rotation === 270) {
            state.width = image.height * state.zoom;
            state.height = image.width * state.zoom;
        } else {
            state.width = image.width * state.zoom;
            state.height = image.height * state.zoom;
        }

        state.top = (state.viewHeight - state.height) / 2;
        state.left = (state.viewWidth - state.width) / 2;

        if (state.top < 0) {
            state.top = 0;
        }

        if (state.left < 0) {
            state.left = 0;
        }

        canvas.style.width = state.width + "px";
        canvas.style.top = state.top + "px";
        canvas.style.left = state.left + "px";
        canvas.style.opacity = "1";

        zoomInfoElem.textContent = Math.round(state.zoom * 100) + "%";
    }

    function dragToScroll() {
        const ele: HTMLElement = document.querySelector(".in-issue-page");
        ele.style.cursor = "grab";

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e) {
            ele.style.cursor = "grabbing";
            ele.style.userSelect = "none";

            pos = {
                left: ele.scrollLeft,
                top: ele.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener("mousemove", mouseMoveHandler);
            document.addEventListener("mouseup", mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = function () {
            ele.style.cursor = "grab";
            ele.style.removeProperty("user-select");

            document.removeEventListener("mousemove", mouseMoveHandler);
            document.removeEventListener("mouseup", mouseUpHandler);
        };

        // Attach the handler
        ele.addEventListener("mousedown", mouseDownHandler);
    }
};
